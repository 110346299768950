<template>
  <pigmentation-template
    :hyperpigmentation="hyperpigmentation"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PigmentationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/pigmentation/PigmentationTemplate';
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const fieldsToReset = [
  'spotShapes',
  'firstSpotsOccurrence',
  'facialSpotLocations',
  'hyperpigmentationDiscomfort'
];

const FIELDS = [...fieldsToReset.map(field), requiredField('hyperpigmentation')];

export default {
  name: 'Pigmentation',
  components: {
    PigmentationTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    hyperpigmentation(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      this.showNextStep();
    }
  }
};
</script>
